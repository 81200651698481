@import '../../assets/scss/bases/variables';
@import '../../assets/scss/bases/mixins';

.declarationRead{
    font-size: remCalc(13);
    overflow: hidden;

    &__arrows{
        margin: 26px 0 40px;
        display: flex;
        justify-content: space-between;
        h2{
            font-size: remCalc(34);
            font-family: $garamond;
            text-align: center;
        }
        .btn{
            width: 40px;
            height: 40px;
            border-radius: 4px;
            border: 1px solid $darkblue;
            position: relative;
            cursor: pointer;
            outline: none;

            svg{
                color: $darkblue;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            &:disabled {
                border-color: $gray;
                cursor: not-allowed;
                opacity: 0.5;

                svg{
                    color: $gray;
                    opacity: 0.5;
                }
            }
        }
    }

    h4{
        margin-top: 16px;
    }

    h4,p{
        padding: 0 16px;
    }

    a{
        cursor: pointer;
    }

    table{
        border-collapse: collapse;
        width: 100%;

        tbody,
        tfoot,
        thead{
            td{
                padding: 12px 16px;
            }
        }

        tfoot,
        .total{
            background: $body-color;
        }

        thead{
            th{
                text-align: left;
                border-bottom: 1px solid $gray01;
                padding: 12px 16px;
            }
        }


        tbody {
            tr{
                color: $gray03;
                font-size: remCalc(13);
                font-weight: 400;
                width: 100%;
                border-bottom: 1px solid $gray01;
                strong {
                    color: $black;
                }
            }

            td:first-child{
                width: 50%;
            }

            td:not(:first-child){
                // color: $gray02;
                font-size: remCalc(13);
                font-weight: 500;
            }
        }
    }
}

