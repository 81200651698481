@import "../../../assets/scss/bases/variables";
@import "../../../assets/scss/bases/mixins";

.ui-dropdown-button {
    &.MuiButtonBase-root {
        background: transparent;
        border: 1px solid $gold;

        .MuiButton-label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $gold;
        }

        &:hover {
            background: $gold-A30;
        }
    }
    &__popper {
        z-index: 2;
    }
}