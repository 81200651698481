@import '../assets/scss/bases/variables';
@import '../assets/scss/bases/mixins';

.dashboard{
    overflow: hidden;

    &-calendar-content{
        @include breakpoint(md-max){
            width: 200%;
        }
    }

    .box-content{
        padding: 0;
        overflow-x: auto;
        overflow-y: hidden;
        @include breakpoint(lg-min){
            overflow: hidden;
        }
    }
    .box-header{
        @include breakpoint(md-max){
            display: block;
            padding: 25px 32px;
            &__title{
                margin-bottom: 20px;
            }
            &__actions{
                margin: -8px;
            }
        }
    }

    &-head{
        display: flex;
        justify-content: space-between;
        margin: 5px 0 20px;
        align-items: flex-end;
        @include breakpoint(md-max){
            flex-flow: column;
            align-items: flex-start;
            margin: 0 0 42px;
        }

        &__number{
            display: flex;
            justify-content: space-between;
            color: $gray03;
            font-size: remCalc(12);
            align-items: flex-end;

            div{
                max-width: 110px;
                margin-left: 40px;
                @include breakpoint(md-max){
                    margin: 0 40px 0 0;
                }
            }

            span{
                color: $darkblue;
                font-size: remCalc(24);
                font-family: $garamond;
                font-weight: 500;
            }
        }
    }
}
.dashboard-table{
    min-width: 980px;
    .ui-tablecell{
        &--warning,
        &--status,
        &--date,
        &--reportDate, 
        &--name,
        &--country,
        &--maison{
            background-color: #F9F9FB;
        }
        &--warning:first-child{
            padding-left: 10px;
            padding-right: 0;
            svg{
                display: block;
            }
        }
        &--risks{
            max-width: 170px;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 3px 6px;
            .MuiChip-root{
                margin: 3px;
            }
        }
        &--name{
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &--warning{
            color: $blue;
            svg{
                width: 18px;
            }
        }
        &--c{
             &--cross{
                color: $red;
                padding-top: 0.25rem;
            }
            text-align: center
        }
        &--r{
            svg{
                color: $blue;
                width: 13px;
            }
        }
        &--actions{
            .MuiButton-root{
                width: 100%;
                padding: 8px 25px;
            }
        }
        &--close{
            padding-right: 10px !important;
            .MuiButton-root{
                width: 100%;
                padding: 8px 25px;
                height: 55px;
            }
        }
    }

    .ui-tableheadcell{
        &--c{
            text-align: center
       }
    }
    
    .MuiTableBody-root{
        tr:hover{
            .ui-tablecell{
                &--warning,
                &--status,
                &--date,
                &--reportDate, 
                &--name,
                &--country,
                &--maison{
                    background-color: $purplelight;
                }
            }
            background-color: $purplelight;
        }
    }
    &__row{
        &--danger{
            .ui-tablecell{
                background-color: rgba($red, 0.2);
            }
            &:hover{
                .ui-tablecell{
                    background-color: inherit;
                }
            }
        }
    }

}

