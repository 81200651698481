
.dashboard-actions{
    display: flex;
    align-items: center;
    margin-left: auto;
    @include breakpoint(md-max){
        flex-wrap: wrap;
    }
      
    &__item{
        margin: 8px;
        &--export{
           svg{
               transform: scaleX(-1);
           }
        }
    }
    .ui-field{
        margin: 0 !important;
    }
    .MuiButtonGroup-root {
        flex-shrink: 0;
    }
    .MuiInputBase-root {
        width: 270px;
    }
    .MuiButtonBase-root{
        flex-shrink: 0;
    }
    .MuiInputBase-input {
        padding: 11px 18px 11px 0;

    }
    .MuiButton-root{
        padding: 8px 18px;
        text-transform: none;
    }
    .ui-splitbutton__action{
        border: 1px solid rgba(161, 119, 0, 0.5);
        background-color: transparent !important;
    }
    .ui-splitbutton__action:hover{
        border: 1px solid rgba(161, 119, 0, 0.5);
        background-color: rgba(161, 119, 0, 0.04) !important;
    }
    .ui-splitbutton__icon{
        border: 1px solid rgba(161, 119, 0, 0.5);
        background-color: transparent !important;
    }.ui-splitbutton__icon:hover{
        border: 1px solid rgba(161, 119, 0, 0.5);
        background-color: rgba(161, 119, 0, 0.04) !important;
    }
    .ui-splitbutton .MuiButtonBase-root .MuiButton-label {
        color: #A17700 !important;
    }
}