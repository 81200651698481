.Dropzone {
  height: 200px;
  width: 200px;
  background-color: #fff;
  border: 1px dashed #CCCCCC;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  color: #999999;

}

.disabled {
  cursor: default;
  background-color: rgba(#CCCCCC, 0.35);
  border: none;
}

.default {
  cursor: pointer;
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none;
}

.Highlight {
  background-color: #CCCCCC;
}

span.browse {
  color: #3FA2F7;
}