.event-recap{
    border-bottom: 2px solid $gray01;
    background:$blue01;
    position: relative;
    z-index: 20;

    &__content{
        padding: 18px 0;
    }

    &__title{
        font-family: $garamond;
        font-weight: 500;
        font-size: remCalc(24);
        line-height: remCalc(30);
        
    }



    &__infos{
        p{
            font-family: $poppins;
            font-size: 12px;
            color: $gray02;
            font-weight: 500;
            margin-bottom: 8px;

            span{
                font-weight: 400;
                color: $gray03;
            }
        }
    }

    &__numbers{
        display: flex;
        justify-content: space-between;
        border-left: 2px solid $gray01;
        align-items: center;

        @include breakpoint(md-max){
            border-left: 0px;
            border-top: 2px solid $gray01;
        }

        p{
            color:$gray03;
            font-size: 12px;
            font-family: $poppins;
            width: 100%;
            text-transform: initial;
        }

        span{
            color: $darkblue;
            font-size: remCalc(24);
            font-family: $garamond;
            font-weight: 500;
        }


        .p-40{
            padding-left: 44px;
            @include breakpoint(md-max){
                padding-left: 0;
            }
        }
    }
}