.ui-modal{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px;
    &__content{
        width: 90vw;
        outline: none;
        margin: auto;
        &--lg {
            max-width: 955px;
        };
        &--md {
            max-width: 655px;
        };
        &--sm {
            max-width: 355px;
        };
    }
    &__close{
        display: flex;
        border: 0;
        background: transparent;
        color: #404545;
        cursor: pointer;
    }
    &__footer{
        border-top: 1px solid $gray04;
        margin: -28px -32px;
        margin-top: 30px;
        padding: 24px 32px;
        &--buttons{
            display: flex;
            justify-content: flex-end;
            button + button{
                margin-left: 24px;
            }
        }
    }

    textarea {
        max-height: 180px;
        overflow: auto !important;
    }
}