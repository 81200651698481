// Force middle element to keep center with fluid with items
@import '../../../assets/scss/bases/variables';
@import '../../../assets/scss/bases/mixins';

.calendar-toolbar > * {
    flex: 1;
    display: flex;
    justify-content: center;
}
.calendar-toolbar > *:first-child > * { margin-right: auto; }
.calendar-toolbar > *:last-child  > * { margin-left: auto;  }


// Style toolbar
.calendar-toolbar{
    display: flex;
    padding: 24px;
    position: relative;
    margin-bottom: 25px;
    &::after{
        content: '';
        position: absolute;
        height: 1px;
        background-color: $gray04;
        bottom: 0px;
        left: 0;
        right: -12px;
        z-index: 1;
        width: 150%;
        @include breakpoint(md-max){
            width: 100%;
        }
    }
    &__navigation{
        display: flex;
        align-items: center;
        svg + svg{
            margin-left: -0.7em;
        }
        button{
            background: transparent;
            border: none;
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            outline: none;
        }
    }
    &__date{
        font-size: 16px;
        font-weight: 500;
        padding: 0 10px;
        text-align: center;
        min-width: 150px;
        margin-bottom: 3px;
    }
}