@import '../../../assets/scss/bases/variables';
@import '../../../assets/scss/bases/mixins';

.dashboard-event-details{
    background: $blue01;
    position: relative;
    @include breakpoint(lg-min){
        overflow-y: auto;
        overflow-x: hidden;
    }
    &__title{
        padding: 27px 0;
        font-weight: 500;
        font-size: remCalc(16);
        position: relative;
        @include breakpoint(md-max){
            padding: 27px 16px;
        }
    }

    & > div{
        @include breakpoint(lg-min){
            overflow-y: auto;
            max-height: 555px;
        }
    }

    .ui-splitbutton .MuiButtonBase-root{
        background-color: $darkblue;
        margin-top: 16px;
        .MuiButton-label{
            text-transform: none;
            color: $white;
        }
    }

    .calendar-details{
        margin: 24px 16px 14px 0px;
        padding: 24px 21px 0 8px;
        background: $white;
        border-radius: 4px;
        
        &:hover{
            background: $purplelight;
        }

        @include breakpoint(md-max){
            margin: 0px 16px 14px 16px;
        }

        &__header{
            display: flex;
            justify-content: space-between;
            color:$gray02;
            label{
                display: flex;
                justify-content: space-between;
                color: $white;
                svg{
                    color: $blue;
                }
            }

            .status{
                margin: 0 5px 0;
            }
        }

        &__infos{
            padding: 16px 24px 24px;
            color: $gray02;
            font-size: remCalc(13);
            line-height: 1.4;
        }
        &__block{
            margin: 16px 0;
            &:first-child{
                margin-top: 0;
            }
        }
        &__risks{
            margin-top: 3px;
        }
        &__title{
            font-weight: 500;
            color: $black;
        }

        &__location{
            font-size: remClac(13);
        }
        &__checked{
            div{
                display: flex;
                align-items: center;
            }
            &__sym{
                justify-content: center;
                width:20px;
                @media screen and (max-width: 700px) {
                    width: 10px;
                    float: none;
                }
                svg{
                    color: $red;
                }
            }
            &__data{
                    flex-grow: 1;
            }
           
        }
    }
}