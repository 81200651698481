@import '../assets/scss/bases/variables';
@import '../assets/scss/bases/mixins';

.prerequisite{
    margin: 0 0 18px;
    .ui-tooltip-label{
        white-space: nowrap;
    }
}

.new-event{

    h1{
        @include breakpoint(lg-min){
            margin-bottom: 0px;
        }
    }

    .table__level{
        border-collapse: collapse;
        width: 100%;

        thead{
            background: $blue01;
            border-bottom: 1px solid $gray01;
        }

        thead th{
            border:none;
            font-size: remCalc(13);
            color: $darkblue;
            font-family: $poppins;
            font-weight: 500;
            text-align: left;
        }

        tbody tr{
            border-bottom: 1px solid $gray01;
        }

        tbody td{
            position: relative;
            color:$gray;
            font-family: $poppins;
            font-weight: 500;
            font-size: remCalc(13);
            padding: 14px 16px;
        }
    }

    .box-content__table{

        @include breakpoint(md-max){
            overflow: auto;
        }

        margin-top: 40px;

        thead th{
            padding: 18px 24px;
            text-align: center;
        }

        .circle{
            border-radius: 100%;
            height: 10px;
            width: 10px;
            background: $blue;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            &-0{
                background: $green;
            }
            &-1{
                background: $yellow;
            }
            &-2{
                background: $red;
            }
            &-3{
                background: $yellow;
            }
            &-4{
                background: $orange;
            }
            &-5{
                background: $red;
            }
        }
    }

    .double-trailing {
        display: inline-block;
        padding: 0px 5px;
        margin-bottom: 15px;
    }

    .message-prevent {
        padding: 5px;
        margin-bottom: 15px;
    }
}