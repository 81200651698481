// ==========================
//	BREAKPOINT
// ==========================

// SCREEN SIZE

$screen-xxs: 320px;
$screen-xs: 374px;
$screen-sm: 600px;
$screen-md: 960px;
$screen-lg: 1280px;
$screen-xl: 1400px;
$screen-xxl: 1540px;

// EXAMPLE USAGE:

// @include breakpoint(sm-min) {
// 	property: value;
// }

@mixin breakpoint($point) {
  // =======================
  //		Upper
  // =========================

  @if $point == xxs-max {
    @media (max-width: $screen-xs - 1) {
      @content;
    }
  } @else if $point == xs-max {
    @media (max-width: $screen-sm - 1) {
      @content;
    }
  }
  // from sm screen size and upper
  @else if $point == sm-max {
    @media (max-width: $screen-md - 1) {
      @content;
    }
  }
  // from md screen size and upper
  @else if $point == md-max {
    @media (max-width: $screen-lg - 1) {
      @content;
    }
  } @else if $point == lg-max {
    @media (max-width: $screen-xl - 1) {
      @content;
    }
  } @else if $point == xxl-max {
    @media (max-width: $screen-xxl - 1) {
      @content;
    }
  } @else if $point == xxl-min {
    @media (min-width: $screen-xxl) {
      @content;
    }
  } @else if $point == xl-min {
    @media (min-width: $screen-xl) {
      @content;
    }
  } @else if $point == lg-min {
    @media (min-width: $screen-lg) {
      @content;
    }
  } @else if $point == md-min {
    @media (min-width: $screen-md) {
      @content;
    }
  } @else if $point == sm-min {
    @media (min-width: $screen-sm) {
      @content;
    }
  } @else if $point == xs-min {
    @media (min-width: $screen-xs) {
      @content;
    }
  } @else if $point == xxs-min {
    @media (min-width: $screen-xxs) {
      @content;
    }
  } @else if $point == xxs-only {
    @media (max-width: $screen-xs - 1) {
      @content;
    }
  } @else if $point == xs-only {
    @media (min-width: $screen-xs) and (max-width: $screen-sm - 1) {
      @content;
    }
  } @else if $point == sm-only {
    @media (min-width: $screen-sm) and (max-width: $screen-md - 1) {
      @content;
    }
  } @else if $point == md-only {
    @media (min-width: $screen-md) and (max-width: $screen-lg - 1) {
      @content;
    }
  } @else if $point == lg-only {
    @media (min-width: $screen-lg) and (max-width: $screen-xl - 1) {
      @content;
    }
  } @else if $point == xl-only {
    @media (min-width: $screen-xl) and (max-width: $screen-xxl - 1) {
      @content;
    }
  } @else if $point == xxl-only {
    @media (min-width: $screen-xxl) {
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: $screen-md - 1) {
      @content;
    }
  } @else if $point == desktop {
    @media (min-width: $screen-md) {
      @content;
    }
  }
}
