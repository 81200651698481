.cityBox {
    background-color: #f5f5f5;
    padding: 10px;
}

.cityName {
    padding: 5px 0px;
    & > * {
        display: block;
    }

    span.info {
        color: gray;
        font-size: 11px;
    }
}

.cityDetails {
    font-size: 12px;

    & > * {
        display: block;
        padding: 2px 0px;
    }

    span {
        font-weight: bold;
    }
}


