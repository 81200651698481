@import '../../../assets/scss/bases/variables';
@import '../../../assets/scss/bases/mixins';

.calendar {
    height: 500px;
}

.calendar-event-item{
    display: block;
    display: flex;
    align-items: center;
    width: 100%;
    background: $blue01;
    padding: 3px;
    color: $gray02;
    border-radius: 4px;
    border-left: 4px solid $gold;
    svg{
        margin-right: 5px;
        color: $blue;
    }

    &--draft{
        border-left: 4px solid $blue;
    }
    &--pendingHq{
        border-left: 4px solid $gold;
    }
    &--pendingBroker{
        border-left: 4px solid $mustard;
    }
    &--pendingRisk{
        border-left: 4px solid $brown;
    }
    &--cancelled{
        border-left: 4px solid $gray04;
    }
    &--accepted{
        border-left: 4px solid $green;
    }
    &--escalated{
        border-left: 4px solid $red;
    }
}

.rbc-event{
    background: transparent !important;
    padding: 0;
    outline: none !important
}

.rbc-calendar{
    padding-bottom: 20px;
    height: 635px;
}

.rbc-toolbar{
    padding: 22px 32px;
    margin-bottom: 24px;
    position: relative;

    &::after{
        content: '';
        position: absolute;
        height: 1px;
        width: 110%;
        background-color: $gray04;
        bottom: 0;
        right: -12px;
    }
}

.rbc-month-view{
    padding: 0 24px;
    border: none;
}

.rbc-month-row,
.rbc-month-header{
    border-left: 1px solid $gray04;
    border-right: 1px solid $gray04;
}

.rbc-month-row:last-child{
    border-bottom: 1px solid $gray04;
}

.rbc-header{
    padding: 5px 0;
    color: $gray03;
    font-family: $poppins;
    font-weight: 400;
}

.rbc-date-cell{
    text-align: center;
    margin: 5px 0;
    color: $gray03;
    font-family: $poppins;
    font-weight: 400;
}

.rbc-show-more{
    color : $gray03;
}
