div.loading-container {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loading-box {
        background-color: white;
        height: 110px;
        width: 150px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }
}