@import "../../../assets/scss/bases/variables";
@import "../../../assets/scss/bases/mixins";

.modal-import-lvl1 {
    .ui-modal {
        .modal-import-lvl1 {
            section {
                display: flex;
                flex-direction: column;
                gap: 4px;
                margin: 0 0 24px;

                p {
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            &__download-button {
                margin-bottom: 16px;
                width: fit-content;
            }

            &__processed-data {
                padding: 1rem 0.5rem;

                .processed-data {
                    padding: 0 0.5rem 0 0;
                    overflow-y: auto;
                    &--error {
                        max-height: 400px;
                    }

                    &--duplicate {
                        max-height: 500px;
                    }

                    h4 {
                        margin: 0 0 16px;

                        + p {
                            margin: 0.5rem 0 0;
                        }
                    }

                    ul {
                        padding: 0;
                        margin: 0;
                        display: grid;
                        gap: 16px;
                        list-style-type: none;

                        li {
                            color: $gray02;
                            position: relative;
                            padding-left: 1rem;

                            &::before {
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 0.35rem;
                                height: 0.35rem;
                                background-color: $danger;
                                border-radius: 50%;
                            }
                        }
                    }
                }

                .duplicate_group {
                    border-radius: 2px;
                    border: 1px solid rgba(153, 153, 153, 0.4);
                    margin-bottom: 16px;

                    &__item {
                        background-color: transparent;
                    }

                    &__selected {
                        background-color: #d9e1e7;
                    }
                }
            }

            &__footer {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 16px;
            }
        }

        .ui-modal__footer.ui-modal__footer--buttons {
            display: flex;
            justify-content: flex-end;
            gap: 16px;
        }
    }
}
