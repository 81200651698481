.ui-autocomplete {
  position: relative;
  &__icon {
    position: absolute;
    left: 10px;
    top: 13px;
  }
  .MuiInputLabel-outlined {
    transform: translate(40px, 13px) scale(1);

  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 5px 5px 5px 35px !important;
  }
}
