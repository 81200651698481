.administration-box {
    .box-content{
        padding: 0;
        overflow-x: auto;
        overflow-y: hidden;
        @include breakpoint(lg-min){
            overflow: hidden;
        }
    }
    .box-header{
        @include breakpoint(md-max){
            display: block;
            padding: 25px 32px;
            &__title{
                margin-bottom: 20px;
            }
            &__actions{
                margin: -8px;
            }
        }
    }

    .head-actions {
        display: flex;
        align-items: center;
    
        &__item {
            margin: 0px 5px;
        }
        &__item:last-of-type{
            margin-left: auto;
        }
    }

    .recycle-icon {
        height: 3rem;
        width: 3rem;

        .start-anim {
            animation: rotate-inf 2s both infinite ;
        }

        @keyframes rotate-inf {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
    }

}

.role-section {
    padding: 10px;
}
    
.fields-management {
    table {
        position: relative;
        border-collapse: collapse; width: 100%;
        border-radius: 5px;

        th {
            background-color: $darkblue;
            color: $white;
        }

        td .ui-switch-field {
            margin: -29px 0px;
            top: -20px;
            position: relative;
        }

        td, th {
            font-family: "Poppins", sans-serif;
            font-size: 14px;
        }

        thead {
            position: relative;
            z-index: 2;
        }

        tbody {
            position: relative;
            z-index: 1;
            &,td.sticky-cell {
                background-color: $blue01;
            }

            &:nth-child(odd) {
                &,td.sticky-cell {
                    background-color: $white !important;
                }
            }
        }

        .sticky-cell {
            position:sticky;
            top: 52px;
        }

        .MuiTableCell-root {
            border: 1px solid rgba(224, 224, 224, 1);

            &:first-child {
                border-left: none;
            }
        }

        thead th {
            position: sticky;
            top: 0;
            z-index: 1;
        }
        tbody th {
            position:
            sticky;
            left: 0;
        }
    }

    .tableFixHead {
        overflow-y: auto;
        height: 55vh;
        border-radius: 5px;
        box-shadow: 3px 5px 10px 0px lightgrey;
        border-radius: 5px;
    }
    
    & > div.role-select-div {
        text-align: center;
        padding-bottom: 10px;

        .ui-field {
            width: 300px;
        }
    }
    
    .actions-sections {
        margin-top: 20px;
        text-align: center;

        button {
            width: 150px;
            margin: 0px 5px
        }
    }
}

.admin-table {
    thead {
        background-color: $white;
    }

    .ui-tablecell {
        &--actions {
            .MuiButton-root {
                width: 100%;
                padding: 8px 25px;
            }
        }
    }
    
    th:first-child, td:first-child {
        padding-left: 20px;
    }

    th:last-child {
        min-width: 85px;
        width: 85px;
        overflow-wrap: anywhere;
    }

    th, td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 350px;
    }

    th {
        width: 18.9%;
        overflow-wrap: anywhere;
    }
    .ui-tablerow{
        background-color: $blue01;
        &:hover{
            background-color: $purplelight;
        }
    }

    &.columns-4 {
        th {
            width: 30.9%;
        }
    }
}