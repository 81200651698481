.MuiChip-root.status{
    height: 20px;
    display: inline-flex;
    align-items: center;
    background-color: $gold;
    min-width: 60px ;
    box-sizing: border-box;

    .MuiChip-label{
        color: $white;
        font-family: $poppins;
        font-weight: 500;
        font-size: 10px;
        text-transform: uppercase;
    }

    // STATUS EVENT
    &.ui-status{
        &--draft{
            background-color: $mintGreen;
        }
        &--pendinghq{
            background-color: $gold;
        }
        &--pendingbroker{
            background-color: $mustard;
        }
        &--pendingunderw{
            background-color: $mustard;
        }
        &--pendingrisk{
            background-color: $brown;
        }
        &--cancelled{
            background-color: $gray04;
            .MuiChip-label{
                color : $gray02;
            }
        }
        &--accepted{
            background-color: $green;
        }
        &--closed{
            background-color: $green;
        }
        &--escalated{
            background-color: $red;
        }
    }
}
