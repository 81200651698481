@import '../../../assets/scss/bases/variables';
@import '../../../assets/scss/bases/mixins';

.box {
    margin-top: 20px;
}
.eventRead{
    h1{
        margin: 0;
    }

    .box-content{
        @include breakpoint(md-max){
            overflow: auto;
        }
    }


    table{
        width: 100%;
        border-collapse: collapse;


        thead{
            background: $body-color;
            th{
                padding: 15px 20px;
                text-align: left;
                width: 20%;

                &:first-child() {
                    width: 140px;
                }
            }
        }

        tbody{
            tr{
                &:hover{
                    background: $blue01;
                    td{
                        font-weight: 700;
                    }
                }
            }
            td{
                border-bottom: 1px solid $gray01;
                padding: 15px 20px;
                &:first-child{
                    color: $blue;
                    font-weight: 400;
                }
            }
        }
    }


    .event-history__status{
        display: flex;
        justify-content: center;
        flex-flow: column;
        padding-bottom: 20px;

        & > div {
            position: relative;
            display: flex;
            align-items: baseline;
        }

        & > div:last-child{
            .status::after{
                display: none;
            }
        }

        .status{
            position: relative;
            display: flex;
            margin-bottom: 20px;
            width: 150px;
            margin-right: 10px;

            &::after{
                position: absolute;
                content: '';
                left: 50%;
                top: 100%;
                transform: translate(-50%, 0%);
                background: $gray01;
                height: 25px;
                width: 2px;
            }

            span{
                font-size: remCalc(13);
            }
        }
    }

}