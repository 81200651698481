@import '../../../assets/scss/bases/variables';
@import '../../../assets/scss/bases/mixins';

.ui-splitbutton{
    margin-right : 10px;

    .MuiButtonBase-root {
        background: $white;
        .MuiButton-label{
            color: $primary;
        }

    }
    &__popper{
        z-index: 2;
    }
}