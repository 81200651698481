.bold-noshift{
    position: relative;
    &__sizer{
        opacity: 0;
        font-weight: bold;
        pointer-events: none;
    }
    &__element{
        position: absolute;
    }
}