body{
    padding-top: 54px;
    @include breakpoint(md-max){
        padding-top: 0px;
    }
}
.header{
    background: $white;
    padding: 12px 0;
    position: fixed;
    z-index: 200;
    top: 0;
    width: 100%;
    @include breakpoint(md-max){
        padding: 15px 0;
        position: relative;
    }

    &-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include breakpoint(md-max){
            flex-flow: column;
        }
    }

    .header-breadcrumbs{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        @include breakpoint(md-max){
            position: relative;
            left: 0;
            transform: translateX(0%);
            width: 100%;
            margin: 10px 0;
        }
    }

    .breadcrumb{
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        background: none;
        font-size: 16px;


        a{
            color: $gold;
            text-decoration: none;
        }

        .breadcrumb-item{
            display: flex;
            align-items: center;

            svg{
                color:$gray03;
            }
        }

        .breadcrumb-item.active{
            color: $gray03;
        }
    }

    &-login{
        @include breakpoint(md-max){
            text-align: left;
            width: 100%;
        }
    }


    &-logo{
        display: flex;
        align-items: center;
        @include breakpoint(md-max){
            flex-flow: column;
            align-items: flex-start;
            width: 100%;
        }
        span {
            font-size: remCalc(14);
            color: $black;
            border-left: 1px solid $black;
            font-family: $poppins;
            padding-left:8px;
            margin-left: 30px;
            font-weight: 500;
            @include breakpoint(md-max){
                margin-left: 0px;
                border: none;
                padding: 10px 0 0 0;
            }
        }
    }

    .dropdown {
        position: relative;
        .header-login {
            outline: none;
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
    }

    .dropdown-menu {
        position: absolute;
        right: -20px;
        box-shadow: 0px 2px 5px 0px gray;
        display: flex;
        flex-direction: column;
        border: 1px solid $gray04;
        border-radius: 5px;
        padding: 8px;
        background-color: $white;
        
        & > a {
            color: $black;
            text-decoration: none;
        }
    }
}