// FONTS
$poppins: "Poppins", sans-serif;
$garamond: "EB Garamond", serif;

// COLORS
$white: #fff;
$black: #000;
$gold: #a17700;
$gold-A30: #a1770060;
$green: #46ad40;
$red: #c44743;
$orange: #ff5c00;
$yellow: #ffcb21;
$mustard: #e09845;
$brown: #a25700;
$danger: #C54C45;

$darkblue: #004165;

$primary: $darkblue;
$secondary: $gold;

$darkblue01: #003a5b;
$darkblue-20: rgba(0, 65, 101, 0.2);
$darkblue-04: rgba(0, 16, 98, 0.04);

$purplelight: #dcdcff;

$blue: #31a6c5;
$mintGreen: #9DBCB0;
$blue01: #f9f9fb;
$blue02: #f5f5fd;

$bluelight-20: rgba(49, 166, 197, 0.2);
$bluelight-40: rgba(49, 166, 197, 0.4);
$bluelight-60: rgba(49, 166, 197, 0.6);
$bluelight-80: rgba(49, 166, 197, 0.8);

$gray: #707474;
$gray01: rgba(64, 69, 69, 0.2);
$gray02: #404545;
$gray03: #707474;
$gray04: #dcddde;

$gray02-10: rgba(64, 69, 69, 0.1);
$gray02-75: rgba(64, 69, 69, 0.75);

$body-color: #f0f1f6;

$material-light-gray: rgba(0, 0, 0, 0.54);
$material-gray: rgba(0, 0, 0, 0.23);
$material-blue: #004165;
