.prerequisite {
  margin: 0 0 18px;
  .ui-tooltip-label {
    white-space: nowrap;
  }
}

.level-event {
  padding-bottom: 24%;
}

.security,
.box-fullwidth {
  width: 100%;
}

.security {
  hr {
    display: none;
  }
}
