@use "src/assets/scss/bases/variables";

.readonly{
    overflow: hidden;
    .content{
        padding-bottom: 0;
    }
    .MuiGrid-item{
        &.readonly__left{
            padding-bottom: 80px;
            padding-right: 55px;
            @include breakpoint(md-max){
                padding-right: 12px;
                padding-bottom: 40px;
            }
        }
        &.readonly__right{
            padding-left: 55px;
            padding-top: 80px;
            padding-bottom: 80px;
            position: relative;
            @include breakpoint(md-max){
                padding: 40px 12px 40px;
            }
            &::before{
                z-index: -1;
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 100vw;
                background:$darkblue-04;
            }
        }
    }

    .box-content {
        button:disabled {
            background-color: variables.$gray;
            border: 1px solid rgba(0, 0, 0, 0.26);
        }
    }

}