// DragAndDrop.scss

@import "../../../assets/scss/bases/variables";
@import "../../../assets/scss/bases/mixins";

.drag-and-drop {
    border: 2px dashed $gray04;
    border-radius: 2px;
    padding: 24px;
    text-align: center;
    margin-bottom: 16px;
    transition: background-color 0.3s;

    &--dragging {
        background-color: rgba(0, 123, 255, 0.1);
        border-color: $primary;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;

        p {
            margin: 0;
        }
    }

    &__loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 24px;
        color: $primary;

        &-icon {
            animation: spin 2s linear infinite;
            font-size: 3rem;
        }

        p {
            margin-top: 8px;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    &__error {
        color: $danger;
        margin-top: 10px;
    }
}
