.ui-switch-field {
  &__field {
    margin-left: -9px;
  }
  &.ui-field--block{
    .ui-switch-field__label{
      font-size: 12px;
    }
  }
}
