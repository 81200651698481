.placeholder-actions {
    padding: 5px;
    text-align: right;

    & > * {
        margin: 0 5px;
    }

    .option:hover {
        background-color: $darkblue01;
        color: $white;
    }

    .option-selected{
        background-color: $darkblue-20;
    }
}
.placeholder-action__item{
    margin: 10px;
}