
.ui-collapsable{
    border-radius: 8px;
    overflow: hidden;

    .MuiExpansionPanelSummary-content{
        display: flex;
        align-items: center;
    }

    h3{
        color: $darkblue;
        font-size: remCalc(16);
        font-family: $poppins;
        font-weight: 500;
        margin-left: 16px;
    }

    hr{
        display: none;
    }

    .number{
        border: 1px solid $darkblue-20;
        width: 33px;
        height: 33px;
        border-radius: 100%;
        position: relative;

        span{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            color: $darkblue;
            font-size: remCalc(12);
            font-family: $poppins;
            font-weight: 500;
        }
    }
}
