
.ui-field {
  display: inline-flex;
  flex-direction: column;
  font-size: 14px;
  &.MuiFormControl-root {
    flex-direction: row;
  }
  &__field {
    width: 100%;
  }

  &__tooltip {
    width: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  &--inline{
    flex-direction: row;
    align-items: center;
    @include breakpoint(md-max){
      flex-direction: column;
      align-items: flex-start;
    }
    &.ui-switch-field{
      align-items: baseline;
    }
    .ui-field__label{
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 10px;
      width: 250px;
    }
  }
  &--small{
    .MuiOutlinedInput-input{
      padding: 8px 11px;
    }
    .MuiInputLabel-outlined{
      transform: translate(14px, 7px) scale(1);
    }
  }

  .MuiInputLabel-outlined{
    @include breakpoint(md-max){
      width: 90%;
    }
  }

  $spacing: 8px;
  body &{
    margin: $spacing;
    width: calc(100% - #{$spacing * 2});
    &--full{
      width: calc(100% - #{$spacing * 2});
      min-width: 100px;
    }
    &--half{
      width: calc(50% - #{$spacing * 2});
      @include breakpoint(md-max){
        width: calc(100% - #{$spacing * 2});
      }
    }
    &--third{
      width: calc(33.3333333% - #{$spacing * 2});
      @include breakpoint(md-max){
        width: 100%;
      }
    }
    &--quarter{
      width: calc(25% - #{$spacing * 2});
      @include breakpoint(md-max){
        width: 100%;
      }
    }
  }

}


.inline-form{
  display: flex;
  @include breakpoint(md-max){
    flex-flow: column;
  }
}