.row__bottom{
    border-top: 1px solid $gray04;
    margin: 24px -16px;
    padding: 24px 24px 0;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    .ui-switch-field{
        width: auto;
    }
}