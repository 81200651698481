@import '../../../assets/scss/bases/variables';
@import '../../../assets/scss/bases/mixins';

.ui-section-title{
    font-family: $poppins;
    font-weight: 500;
    padding-bottom: 32px;
    font-size: remCalc(14);
    color: $darkblue;
    text-align: left;
    margin: 0px 8px 0;
    text-transform: initial;
}
