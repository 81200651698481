.footer{
    width: 100%;
    background: $white;
    color: $black;
    font-family: $poppins;
    font-weight: 300;
    font-size: remCalc(12);

    &-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
        width: 100%;
    }

    a{
        color: $black;
        padding: 0 12px;
    }

    a:first-child{
        border-right: 1px solid $black;
    }

    a:nth-child(2){
        padding-right: 0;
    }
}