.box{
    width: 100%;
    border-radius: 8px;
    background: $white;
    margin: 0 0 40px;
    overflow: hidden;

    &-content{
        padding: 28px 32px;
    }

    &-header{
        min-height: 80px;
        background: $blue01;
        font-family: $poppins;
        color: $darkblue;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 32px;
        h2{
            font-weight: 500;
            font-size: remCalc(16);
        }
    }

    &--alt{
        background: $darkblue;
        color: $white;

        button{
            border: 1px solid $white;
        }

        .MuiButton-label{color: $white;}


        .box-header{
            background: $darkblue01;
            color: $white;
            font-family: $garamond;
            h2{
                font-size: remCalc(24);
            }
        }


        .box-content{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 0px;
            @include breakpoint(md-max){
                flex-flow: column;
            }
        }

        .btn-white{
            background-color: $white;
            .MuiButton-label{color: $darkblue;}

            &:hover{
                .MuiButton-label{color: $white;}
            }

            &--outlined{
                background-color: transparent;

            }
        }

        p{
            margin: 0 18px;
            @include breakpoint(md-max){
                margin: 18px 18px;
            }
        }
    }

}
