.Upload {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
}
  
.Content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
}
  
.Files {
    margin-bottom: 15px;
    align-items: flex-start;
    justify-items: flex-start;
    flex: 1;
    overflow-y: auto;
}
  
.Actions {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 32px;
}
  
.Title {
    margin-bottom: 5px;
    font-weight: 500;
    color: #FF005C;
}

.CheckIcon {
    opacity: 0.5;
    margin-left: 32px;
}
  
.ProgressWrapper {
    display: inline-block;
    /* flex: 1;
    flex-direction: row;
    align-items: center; */
}

.UpperCase {
    text-transform: uppercase;
}

.Details {
    font-weight: 500;
    font-size: 14px;
}

.Encrypting {
    display: inline-block;
    color: #64C255;
}

.Lock {
    position: relative;
    right: 5px;
    bottom: -10px;
    margin-right: 16px;
}

.Encrypting > *{
    margin: 0px 2px;
}