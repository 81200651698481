.pdf-viewer{
    display: flex;
    justify-content: center;
    align-items: center;
    &__close{
        position: absolute;
        right: 10px;
        top: 10px;
        .MuiIconButton-root{
            color: #fff;
        }
    }
}

.pdf-viewer-content{
    width: calc(100vw - 120px);
    height: calc(100vh - 120px);
}

.pdf-viewer-opener{
    position: relative;
    cursor: pointer;
    &:hover{
        .upload__icon{
            opacity: 0.1;
            transition: opacity 0.3s;
        }
    }
    &__icon{
        transition: opacity 0.3s;
        position: absolute;
        top: 0;
        left: 0px;
        bottom: 0;
        display: flex;
        align-items: center;
        background: rgba(#000, 0);
        opacity: 0;
        .pdf-viewer-opener:hover &{
            opacity: 1;
        }
        svg{
            font-size: 27px;
        }
    }
}