@import '../../../assets/scss/bases/variables';
@import '../../../assets/scss/bases/mixins';

#deletePopup, #replacePopup {
    .box{
        width: fit-content;
    
        &-header{
            min-height: 45px;
        }
    }
    
    .ui-modal__footer{
        padding: 10px 32px;
    }
}

.upload{
    &__icon{
        display: flex;
        align-items: center;
    }
    &__title{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $gray01;
        align-items: baseline;
        margin-bottom: 0;
        padding-bottom: 23px;
        width: 100%;

        h3{
            display: flex;
            font-family: $garamond;
            font-size: remCalc(24);
        }
    }

    &__number{
        font-size: remCalc(12);
        font-family: $poppins;
        display: flex;
        color: $gray03;

        span{
            margin-right: 5px;
        }
    }

    &__tooltip{
        margin-left: 10px;
    }

    .row{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0 ;
        border-bottom: 1px solid $gray01;
        margin: 0;
    }

    .Dropzone {
        margin-top: 20px;
        width: 100%;
    }



    &__select{
        width: 50%;
        display: flex;

        .btn{
            border: none;
            background: none;
        }
    }

    &__infos{
        width: 40%;
        span{
            display: block;
        }

        .Filename{
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: remCalc(14);
            font-weight: 500;
            text-transform: capitalize;
        }
        .UpperCase,
        .Filesize{
            font-size: remCalc(11);
            font-weight: 400;
            color: $gray02-75;
        }
    }

    .hidden{
        display: none;
    }

    .warning-container{
        display: flex;
        color: $orange;
        column-gap: 10px;
        align-content: center;
        padding-bottom: 4px;
    }

    .warning-message{
        color:$orange;
        margin-top: 4px;
    }

    .loading-delete{
        max-width: 20px !important;
        max-height: 20px !important;
    }

}