@use 'src/assets/scss/bases/variables' as vars;

.label {
  transform: translate(4px, 10px) scale(0.75);
  padding-left: 5px;
  padding-right: 5px;
  font-size: inherit;
  line-height: 1.33;
  color: vars.$material-light-gray;
  background-color: white;
  z-index: 1;
  width: min-content;
}

.ui-rich-text-editor-container:hover {
  .quill {
    border-color: black;
  }
}

.ui-rich-text-editor:focus-within {
  .quill {
    box-shadow: 0 0 0 1px vars.$material-blue;
  }

  .label {
    color: vars.$material-blue;
  }
}

.quill {
  border: 1px vars.$material-gray solid;
  border-radius: 4px;
  box-sizing: border-box;

  .ql-toolbar {
    border: 0;
    border-bottom: 1px darkgray solid;
  }

  .ql-container {
    overflow-y: auto;
    max-height: 200px;
    height: 200px;
    cursor: default;
    border: 0;
    box-sizing: border-box;

    ::-webkit-scrollbar {
      width: 10px;
      cursor: pointer !important;
    }

    ::-webkit-scrollbar-thumb {
      background: #9e9e9e;
      border-radius: 4px;
      cursor: pointer !important;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #c7c7c7;
      cursor: pointer !important;
    }
  }

}