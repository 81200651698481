.ui-tooltip-label {
  display: inline-flex;
  align-items: center;
  &__label {
    pointer-events: none;
  }
  &__icon {
    display: inline-flex;
    align-items: center;
    margin-left: 0.4em;
  }
}
