.ProgressBar {
    width: 100%;
    height: 8px;
    background-color: #C4C4C4;
    border-radius: 2px;
}
  
.Progress {
    background-color: #525252;
    height: 100%;
    margin: 0;
    border-radius: 2px;
}