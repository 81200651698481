@use "src/assets/scss/bases/variables";

.error{
  &-container{
    margin-top: 5rem;
    margin-bottom: 5rem;
    text-align: center;
  }

  &-title{
    color: variables.$red;
  }

}

