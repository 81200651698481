.goToTopBtn {
    visibility: hidden;
    position: fixed !important;
    right: 50px;
    top: 50%;
}

.goToTopBtn--white {
    background-color: #fff !important;
}
