*, body, p,span,div,a{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html{
    font-size: 13px;
}
body{
    background: $body-color;
}


h1{
    font-family: $garamond;
    font-weight: 700;
    font-size: remCalc(34);
    padding: 10px 0 10px;

}

p{
    margin: 8px 0;
    line-height: 1.125rem;
    font-size: remCalc(12);
    font-family: $poppins;
    font-weight: 400;
}

// CONTAINER + CONTENT
.row{
    margin: -8px;
    &--flex{
        display: flex;
        flex-wrap: wrap;
        > .ui-field{
            width: auto;
        }
    }
   
}
.valign--center{
    align-items: center;
}
.content{
    @include breakpoint(lg-min){
        padding-top: 30px;
    }
}

.MuiGrid-container{
    @include breakpoint(md-max){
        flex-flow: column;
    }
}

// BUTTONS

.btn{
    .MuiButton-label{
        font-size: remCalc(12);
        font-family: $poppins;
        font-weight: 500;
        text-transform: initial;
    }

    &-white{
        .MuiButton-label{
            color: $darkblue;
        }
    }

    &-white-outline{
        .MuiButton-label{
            color: $white;
        }
    }
}

//input
.group-input{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .MuiFormControl-root:first-child{
        margin: 0 12px 0 0;
    }

    .MuiFormControl-root:nth-child(2){
        margin: 0 0 0 12px;
    }
}


// YES OR NO TABLE
.yes,.no{
    text-transform: uppercase;
}

.yes{
    color: $green;
}
.no{
    color: $red;
}

// BACK TO THE LIST
.back-list{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $gray02;
    text-decoration: none;
    margin-top: 24px;

    svg{
        margin-right: 5px;
        color: $gray02;
    }
}

// ADDRESS
.address{
    color: $blue;
    text-decoration: underline;
}

// CONTENT
.section{
    border-bottom: 1px solid $gray01;
    margin: 0 -32px 40px;
    padding: 0 32px 40px;
}

.content.declaration,
.declarationRead .content,
.content.eventRead{
    padding-top: 0px;
    .MuiExpansionPanelDetails-root{
        padding: 40px 32px;
    }
}


.ui-section-content{
    @include breakpoint(md-max){
        overflow: auto;
    }
}

