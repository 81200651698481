.MuiChip-root.tags{
    height: 20px;
    margin-right: 5px;
    margin-top: 5px;
    background-color: $gold;
    display: inline-flex;
    align-items: center;

    .MuiChip-label{
        color: $white;
        font-family: $poppins;
        font-weight: 500;
        font-size: 10px;
        text-transform: uppercase;
    }
    
    &.primary {
        background-color: $primary;
    }
}