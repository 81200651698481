@import '../../../assets/scss/bases/variables';
@import '../../../assets/scss/bases/mixins';

.security-concept-modal{
    &__comments{
        padding: 0 !important;
    }
}

.security-concept-table {
    width: 100%;
    border-collapse: collapse;
    thead tr{
        background: $body-color;

    }
    th {
        text-align: left;
        &:nth-child(4) {
            width: 85px;
            display: flex;
        }
    
        &:nth-child(5) {
            width: 20px;
        }
    }

    td {
        .bold-noshift{
            &__sizer,
            &__element{
                display: inline-block;
                max-width: 150px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        div.version {
            display: flex;
            svg {
                margin-left: 5px;
            }
        }
    }
    td, th{
        padding: 15px 20px;
    }

    tbody tr:hover{
        background: $blue01;
        font-weight: bold;

    }
}

.overflowing{
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}