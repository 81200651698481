.ui-table{

    font-weight: 300;
    .ui-tableheadcell{
        white-space: pre-line;
        word-wrap: break-word; 
    }
    .MuiTableCell-root{
     font-size: 13px;
    }
    th{
        padding: 20px 6px;
        font-weight: 300;
        svg{
            color: $blue;
        }
        .MuiTableSortLabel-active{
            font-weight: 500;
        }
    }
    td{
        word-wrap: break-word;
        padding: 6px 10px;
        &:first-child{
            padding-right:30px;
        }
        &:last-child{
            padding-right:30px;
        }
    }
    &__footer {
        padding: 15px;
        display: flex;
        align-items: center;
     > * {
        flex: 1;
        display: flex;
        justify-content: center;
      }

     > *:first-child > * { margin-right: auto; }

     > *:last-child  > * { margin-left: auto;  }

    

     .MuiPagination-root .MuiButtonBase-root{
         background-color: transparent;
         width: 25px;
         min-width: 25px;
         height: 25px;
         border-color: transparent;
         &:hover{
             color: $gold
         }
         &.Mui-selected{
             border-color: $gold;
             color: $gold;
             background-color: transparent;
         }
     }
    }
    &__action{
        .MuiButtonBase-root{
            padding: 8px 25px;
            text-transform: none;
        }
    }
}

.fixed{
    table-layout: fixed;
}

.ui-pagination{
    display: flex;
    ul{
        list-style: none;
        display: flex;
        li:first-child{
            margin-right: 20px;
        }
        li:last-child{
            margin-left: 20px;
        }
    }
    .ui-pagination__btn{
        height: 25px;
        width: 25px;
        min-width: 25px !important;
        border-radius: 200px;
        border-color: $gold;
        &--current{
            color: $gold
        }

        svg{
            width: 15px;
            height: 15px;
        }

    }
    &__elipsis{
        line-height: 1.6;
        display: inline-flex;
    }

}